<template>
    <button
        v-if="!href"
        :class="classNames"
        :type="type"
    >
        <span class="content">
            <span class="icon" v-if="$slots.icon">
                <slot name="icon"/>
            </span>

            <slot></slot>
        </span>
    </button>

    <Link
        v-else
        :class="classNames"
        :href="href"
    >
        <span class="content">
            <span class="icon" v-if="$slots.icon">
                <slot name="icon"/>
            </span>

            <slot></slot>
        </span>
    </Link>
</template>

<script lang="ts" setup>
import {computed} from "vue";
import {BaseButtonProps} from "@/Components/Shared/types";
import {Link} from "@inertiajs/vue3";

const props = defineProps<BaseButtonProps>();
const classNames = computed<Record<string, boolean>>(() => {
    return {
        'btn': true,
        'btn-round': props.round,
        'btn-square': props.square,
        'btn-icon': props.icon,
        'btn-small': props.compact,
        'btn-large': !props.icon && !props.compact,
    };
});

</script>

<style lang="scss">
.btn {
    display: inline-flex;
    background-color: var(--primary);
    margin-block: auto;
    color: var(--text-secondary);
    border: 1px solid var(--primary);
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 1.6rem;
    line-height: var(--padding-lg);
    font-style: normal;
    font-weight: 400;
    font-family: "Brandon Text";
    border-radius: 50rem;
    text-decoration: none;

    .content {
        display: inline-flex;
        gap: 0.8rem;
    }

    span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    span.icon {
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        > * {
            height: 16px;
        }
    }

    &.no-flex {
        span {
            display: block;
        }
    }

    &.btn-transparent {
        background-color: transparent;
        border-color: transparent;

        &:hover {
            border-color: transparent;
        }
    }

    &.btn-inverse {
        background-color: transparent;

        &:hover {
            background-color: var(--primary);
            border-color: var(--primary);
        }
    }

    div svg,
    * {
        path {
            fill: var(--text-secondary);
        }
    }

    &.btn-small {
        padding: 0.5rem 1rem;
        font-size: 1.2rem;
    }

    &.btn-large {
        padding: 0.8rem var(--padding-lg);
        height: unset;
        line-height: 2.4rem;
        align-items: center;
    }

    &.btn-icon {
        padding: .5rem;
        width: 4rem;
        height: 4rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        svg {
            display: inline-block;
            width: 2rem;
            height: 2rem;
        }

        &.btn-small {
            width: 3rem;
            height: 3rem;
        }
    }

    &:hover {
        border-color: var(--text-inactive);

        span.icon svg path {
            fill: var(--corebox-primary);
        }
    }

    &.btn-round {
        border-radius: 100%;
        aspect-ratio: 1/1;
        width: var(--padding-lg);
        height: var(--padding-lg);
        margin-block: auto;
    }

    &.btn-square {
        border-radius: 0;
        padding: 1rem;

        &.btn-small {
            padding: .2rem .5rem;
        }
    }
}
</style>
